import { Link } from "gatsby";
import React, { PropsWithChildren, ReactElement } from "react";
import useIsBrowser from "../../../hooks/useIsBrowser";
import styles from "./hero.module.scss"

interface PrismicTextField {
    type: string, text: string, spans: any[]
}
interface ClubHours {
    day: Array<PrismicTextField>,
    hours: Array<PrismicTextField>
}
interface HeroProps {
    title: string;
    subtitle?: string;
    backgroundImage?: string;
    backgroundAlt?: string;
    address?: Array<PrismicTextField>;
    phone?: Array<PrismicTextField>;
    hoursOpen?: Array<ClubHours>;
    club?: number;
    googleMapsUrl?: string;
}

const LocationsTemplate = (props: PropsWithChildren<HeroProps>): ReactElement => {
    const { title, subtitle, backgroundImage, backgroundAlt, address, phone, hoursOpen, googleMapsUrl } = props
    let hourClose = null;

    const { isBrowser, key } = useIsBrowser();

    const displayCloseHour = () => {
        let currentDay = new Date().getDay();
        let sun = 0;
        let mon = 1;
        let tue = 2;
        let wed = 3;
        let thu = 4;
        let fri = 5;
        let sat = 6;

        if( hoursOpen){
            if (currentDay === sun && hoursOpen[0].day[0].text.toLowerCase() === "sun") {
            const line = hoursOpen?.find((hoursLine) => hoursLine.day[0]?.text.toLowerCase() === "sun")
            hourClose = line?.hours[0].text.split("-")[1]
            } else if (currentDay === sat  && hoursOpen[0].day[0].text.toLowerCase() === "sat") {
                const line = hoursOpen?.find((hoursLine) => hoursLine.day[0]?.text.toLowerCase() === "sat")
                hourClose = line?.hours[0].text.split("-")[1]
            } else if (currentDay === fri  && hoursOpen[0].day[0].text.toLowerCase() === "fri" ) {
                const line = hoursOpen?.find((hoursLine) => hoursLine.day[0]?.text.toLowerCase() === "fri")
                hourClose = line?.hours[0].text.split("-")[1]
            } else if ( (currentDay === mon || currentDay === tue || currentDay === wed || currentDay === thu) && hoursOpen[0].day[0].text.toLowerCase() === "mon-thu") {
                const line = hoursOpen?.find((hoursLine) => hoursLine.day[0]?.text.toLowerCase() === "mon-thu")
                hourClose = line?.hours[0].text.split("-")[1]
            } else if ((currentDay === mon || currentDay === tue || currentDay === wed || currentDay === thu || currentDay === fri) && hoursOpen[0].day[0].text.toLowerCase() === "mon-fri" ) {
                const line = hoursOpen?.find((hoursLine) => hoursLine.day[0]?.text.toLowerCase() === "mon-fri")
                hourClose = line?.hours[0].text.split("-")[1]
            } else if ((currentDay === sat || currentDay === sun ) && hoursOpen[0].day[0].text.toLowerCase() === "sat-sun" ) {
                const line = hoursOpen?.find((hoursLine) => hoursLine.day[0]?.text.toLowerCase() === "sat-sun")
                hourClose = line?.hours[0].text.split("-")[1]
            }
        }
    }

    displayCloseHour();

    if (!isBrowser) return null as any;

    return (
        <>
            <div className={styles.wrapper} key={key}>
                {backgroundImage && <img src={backgroundImage} alt={backgroundAlt} className={styles.img} />}
                <div className={styles.displayWrapper}>
                    {title && <h1 className={styles.heroTitle}>{title}</h1>}
                    {subtitle && <h3>{subtitle}</h3>}
                    {hourClose && <p className={styles.openUntilAdvice}>Open until {hourClose} today</p>}
                    <div className={styles.hoursAndDaysWrapper}>
                        {address && <div className={styles.addressWrapper}>
                            <h2>Address</h2>
                            {address.map((textLine: PrismicTextField, i: number) => (
                                <p key={i}>{textLine?.text}</p>
                            ))}
                            {phone && phone.map((textLine: PrismicTextField, i: number) => (
                                <>
                                    <a className={styles.phoneLink} href={`tel:${textLine?.text.replace(/[^0-9]/ig, "")}`} key={i}>{textLine?.text}</a>
                                </>
                            ))}
                        </div>}
                        {typeof hoursOpen !== "undefined" && hoursOpen.length > 0 && hoursOpen[0].day.length>0 && <div className={styles.hoursWrapper}>
                            <h2>Club Hours</h2>
                            {hoursOpen.map((hoursLine: ClubHours, i: number) => (
                                <div className={styles.lineDayHoursWrapper} key={i}>
                                    {hoursLine.day && <p className={styles.dayName}>{hoursLine.day[0]?.text}</p>}
                                    { hoursLine.hours && <p className={styles.hoursRange}>{hoursLine.hours[0]?.text}</p>}
                                </div>
                            ))}
                        </div>}
                    </div>
                    <Link to="/try-us" className={styles.contactBtn} state={{club : props.club}}>
                        Try us
                    </Link>
                    {googleMapsUrl && <a className={styles.contactBtn} target="_blank" href={googleMapsUrl}>
                        Visit us
                    </a>}
                </div>
            </div>
        </>
    );
};

export default LocationsTemplate;
